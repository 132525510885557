import { FC, memo } from "react";

import Utils from "utils/Utils";

const ProfileImage: FC<{
  src: string;
  alt?: string;
  big?: boolean;
  size?: number;
  className?: string;
}> = ({ src, alt, big, size, className }) => {
  size = size || (big ? 60 : 30);
  return (
    <img
      height={size}
      width={size}
      alt={alt}
      className={`${className} rounded-circle`}
      src={Utils.constructImageUrl(`${size}x${size}`, "jpg", src)}
      style={{ objectFit: "cover" }}
      loading={"lazy"}
    />
  );
};

export default memo(ProfileImage);
