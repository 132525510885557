import { FC, ReactNode } from "react";

import { EmptyClose } from "common/Icons/Icons";
import styles from "./Drawer.module.scss";

interface DrawerProps {
  show: boolean;
  onHide: () => void;
  header?: string | ReactNode;
  children?: ReactNode;
  placement?: "top" | "bottom" | "right" | "left";
  size?: number | string;
  className?: string;
  closeIcon?: ReactNode;
}

const Drawer: FC<DrawerProps> = ({
  show,
  header,
  children,
  onHide,
  placement = "right",
  size = 50,
  closeIcon,
  className,
}) => {
  const sizeStyle =
    placement === "left" || placement === "right"
      ? { width: size }
      : { height: size };

  return (
    <>
      <div
        className={`${styles.backdrop} ${
          show ? styles.active : ""
        } ${className}`}
        onClick={onHide}
      />
      <div
        style={sizeStyle}
        className={`${styles.drawer} ${show ? styles.show : ""} ${
          styles[placement]
        }`}
      >
        {header && (
          <div
            className={`d-flex justify-content-between align-items-center ${styles.header}`}
          >
            <div>{header}</div>
            <div className="pointer" onClick={onHide}>
              {closeIcon || <EmptyClose />}
            </div>
          </div>
        )}
        {children}
      </div>
    </>
  );
};

export default Drawer;
