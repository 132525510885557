import CardPrimary from "common/Cards/CardPrimary";
import Link from "next/link";

const EmptyBookings = () => (
  <CardPrimary className={"text-center"}>
    <p>You do not have any active booking with us.</p>
    <Link href={"/search"}>
      <a className={"btn callToAction mt-4"}>Start Exploring Experiences</a>
    </Link>
  </CardPrimary>
);
export default EmptyBookings;
