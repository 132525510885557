import { BookingStatus, IBookings } from "./types";
import { FC, useCallback, useEffect, useState } from "react";

import Analytics from "utils/Analytics";
import AnnouncementsDrawer from "./AnnouncementsDrawer";
import EmptyBookings from "./EmptyBookings";
import FabulousAnnouncements from "./FabulousAnnouncements";
import ImportantBookingUpdates from "./ImportantUpdates";
import Link from "next/link";
import MembershipCard from "./MembershipCard";
import MyBookingsTabs from "./MyBookingsTabs";
import { NormalLoader } from "common/loaders/Loaders";
import { RadioChangeEvent } from "antd";
import { useGet } from "services/api/useFetch";
import useToggle from "repository/hooks/useToggle";

const bookingStatus = [
  {
    key: BookingStatus.SUCCESS,
    value: "Successful",
  },
  {
    key: null,
    value: "All",
  },
];

type QueryParams = {
  page: number;
  limit: number;
  use_pagination_v2: boolean;
  is_basket?: boolean;
  status?: BookingStatus;
};

const MyMemberships: FC = () => {
  const [showDrawer, setShowDrawer] = useToggle(false);
  const [status, setStatus] = useState(BookingStatus.SUCCESS);

  const [bookings, { loading }, getBookings] = useGet("v1/orders/", [], {
    initialLoading: true,
  });

  const handleBookingStatusChange = useCallback((e: RadioChangeEvent) => {
    setStatus(e.target.value);
    getUserBookings(e.target.value);
  }, []);

  const getUserBookings = useCallback((bookingStatus: BookingStatus) => {
    const queryParams: QueryParams = {
      page: 1,
      limit: 20,
      use_pagination_v2: true,
      is_basket: true,
    };
    if (bookingStatus) queryParams.status = bookingStatus;
    getBookings({
      queryParams,
    });
  }, []);

  useEffect(() => {
    getUserBookings(status);
  }, [getUserBookings]);

  if (loading) return <NormalLoader />;

  return (
    <div className="row">
      <div className="col-md-4 order-md-2 mb-sm-4">
        <div className="d-none d-md-block mb-4">
          <h5>Important Updates</h5>
          <ImportantBookingUpdates />
        </div>
        <div className="d-block d-md-none ">
          <AnnouncementsDrawer show={showDrawer} onHide={setShowDrawer} />
          <FabulousAnnouncements onClick={() => setShowDrawer(true)} />
        </div>
      </div>
      <div className="col-md-8 order-md-1">
        <MyBookingsTabs
          handleChange={handleBookingStatusChange}
          bookingStatus={bookingStatus}
          status={status}
        />
        {bookings.data.length ? (
          bookings.data.map((booking: IBookings) => (
            <div
              key={`${booking.display_booking_id}`}
              className="col-md-12 mb-4"
            >
              <Link href={`/booking/basket/${booking.booking_id}`}>
                <a
                  onClick={() =>
                    Analytics.track("booking_link", {
                      package_id: booking.basket_id,
                    })
                  }
                >
                  <MembershipCard item={booking} />
                </a>
              </Link>
            </div>
          ))
        ) : (
          <EmptyBookings />
        )}
      </div>
    </div>
  );
};

export default MyMemberships;
