import { useEffect, useState } from "react";

import { ChevronDown } from "common/Icons/Icons";
import Dropdown from "common/dropdown/Dropdown";
import Lockr from "lockr";

const CURRENCY = [
  {
    id: 1,
    name: "INR",
    symbol: "₹",
    value: "INR",
  },
  {
    id: 2,
    name: "USD",
    symbol: "$",
    value: "USD",
  },
];

const PriceDropdown = () => {
  const [selected, setSelected] = useState(CURRENCY[0]);

  useEffect(() => {
    const country = Lockr.get("country");
    if (country === "US") {
      setSelected(CURRENCY[1]);
    }
  }, []);

  const onClick = (item) => {
    setSelected(item);
    const country = item.value === "USD" ? "US" : "IN";
    Lockr.set("country", country);
    window.location.reload();
  };

  return (
    <Dropdown
      overlay={
        <div className="br-8 p-2">
          {CURRENCY.map(({ name, id, symbol, value }) => (
            <div
              key={id}
              className={`fw-600 fs-14 pointer ${
                selected.value === value ? "text-primary" : "text-black"
              }`}
              onClick={() => {
                onClick({ name, id, symbol, value });
              }}
            >
              {symbol} {name}
            </div>
          ))}
        </div>
      }
    >
      <span
        className={`ms-2 text-primary d-flex flex-row align-items-center pointer`}
      >
        {
          <span className="fs-14 fw-600">
            {selected.symbol} {selected.name}
          </span>
        }
        <ChevronDown height={20} fill={"#1ab277"} />
      </span>
    </Dropdown>
  );
};

export default PriceDropdown;
