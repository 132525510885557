import { Heart, Tag, User } from "common/Icons/Icons";

import MyBookings from "./booking/MyBookings";
import MyMemberships from "./booking/MyMemberships";
import MyProfile from "./MyProfile";
import MyWishList from "./MyWishList";

export const PROFILE_TABS = [
  {
    title: "My Profile",
    path: "my-profile",
    icon: <User />,
    component: <MyProfile />,
  },
  {
    title: "My Membership",
    path: "my-membership",
    icon: <Tag />,
    component: <MyMemberships />,
  },
  {
    title: "My Bookings",
    path: "my-bookings",
    icon: <Tag />,
    component: <MyBookings />,
  },
  {
    title: "My Wish List",
    path: "my-wish-list",
    icon: <Heart />,
    component: <MyWishList />,
  },
];
