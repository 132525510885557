import Analytics from "utils/Analytics";
import { ChevronDown } from "common/Icons/Icons";
import CustomCollapse from "common/collapse/Collapse";
import Divider from "common/Divider";
import Drawer from "common/drawer/Drawer";
import { FC } from "react";
import Link from "next/link";
import { PROFILE_TABS } from "components/profile/ProfileUtils";
import SocialMediaStrip from "common/social/SocialMediaStrip";
import Utils from "utils/Utils";
import styles from "./styles.module.scss";
import { useAuth } from "services/AuthService/AuthProvider";
import { useCommonProvider } from "services/contextProviders/CommonProvider";
import { useLogout } from "utils/customHooks";
interface HeaderDrawerProps {
  show: boolean;
  onClose: () => any;
}

const HeaderDrawer: FC<HeaderDrawerProps> = ({ show, onClose }) => {
  const { config } = useCommonProvider();
  const { isAuthenticated, userDataLoading } = useAuth();
  const logout = useLogout();

  const isLoggedOut = !isAuthenticated && !userDataLoading;

  return (
    <Drawer
      header={<h1 className={"fs-18 fw-500 mb-0"}>bwtexperiences</h1>}
      size="80%"
      show={show}
      onHide={onClose}
    >
      <div>
        <Link href={"/one-membership"}>
          <a
            className={`py-3 fs-12 fw-600 d-block text-uppercase ${styles.gradientText}`}
            onClick={() => Analytics.track("nav_item", { item: "About" })}
          >
            One Membership
          </a>
        </Link>
      </div>
      {config
        .filter((it) => it.name !== "Tripflix")
        .map(({ name, sub_categories, path } = {}) => {
          if (Utils.arrayCheck(sub_categories).length === 0)
            return (
              <Link key={name} href={path}>
                <a className="py-3 fs-12 fw-600 d-block text-uppercase">
                  {name}
                </a>
              </Link>
            );
          else
            return (
              <CustomCollapse
                key={name}
                header={
                  <p className="text-primary text-uppercase fs-12 fw-600 m-0 py-3">
                    {name} <ChevronDown height={20} fill={"#1ab277"} />
                  </p>
                }
              >
                {sub_categories.map((item) => (
                  <Link key={item?.name} href={item?.path}>
                    <a className="py-2 ms-3 fs-13 d-block">{item?.name}</a>
                  </Link>
                ))}
              </CustomCollapse>
            );
        })}

      {isLoggedOut && (
        <div>
          <Link href={"/login"}>
            <a
              className={"btn callToActionOutlineNormal signUp"}
              onClick={() => Analytics.track("nav_item", { item: "signup" })}
            >
              <span className={"fs-12 fw-600 text-uppercase"}>
                LOGIN / SIGN UP
              </span>
            </a>
          </Link>
        </div>
      )}
      {!isLoggedOut && (
        <CustomCollapse
          header={
            <p className="text-primary text-uppercase fs-12 fw-600 m-0 py-3">
              Profile <ChevronDown height={20} fill={"#1ab277"} />
            </p>
          }
        >
          {PROFILE_TABS.map(({ title, path, icon }) => (
            <Link key={title} href={`/profile/${path}`}>
              <a className="py-2 ms-3 fs-13 d-block">
                {icon} {title}
              </a>
            </Link>
          ))}
        </CustomCollapse>
      )}
      {!isLoggedOut && (
        <p
          className={`text-primary text-uppercase pointer py-3 fs-12 fw-600 m-0`}
          onClick={() => logout()}
        >
          Logout
        </p>
      )}
      <div>
        <SocialMediaStrip className="mt-4" />
      </div>
      <Divider />
      <p className={"mb-1 fs-10"}>
        Made with{" "}
        <span role={"img"} aria-label={"love"}>
          ❤️
        </span>
        &nbsp; by BookWell Technologies LLP.
      </p>
      <p className={"fs-10"}>
        © Copyright {new Date().getFullYear()} - All Rights Reserved
      </p>
    </Drawer>
  );
};

export default HeaderDrawer;
