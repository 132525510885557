import { BookingStatus, IBookings } from "./types";

import Analytics from "utils/Analytics";
import CardPrimary from "common/Cards/CardPrimary";
import { ChevronDown } from "common/Icons/Icons";
import { FC } from "react";
import Label from "common/text/Label";
import Link from "next/link";
import Utils from "utils/Utils";
import dayjs from "dayjs";
import { getStatus } from "./services/getBookingStatus";
import parse from "html-react-parser";

const MembershipCard: FC<{ item: IBookings; bookingDetails?: boolean }> = ({
  item,
}) => {
  const {
    status,
    booking_id,
    display_booking_id,
    is_booking_rated,
    can_rate_booking,
    updated,
    can_book_again,
    start_time,
    end_time,
    joining_details,
    pax,
    pickup,
    start_date,
    end_date,
    amount,
    can_calendar,
    is_calendared,
    accommodation_details,
    subscription_details,
    package: experience,
  } = item || {};

  const {
    message,
    id,
    name,
    url,
    time_slot,
    things_to_carry,
    instructor_name,
  } = experience || {};
  const daysRemaining = dayjs
    .unix(end_date)
    .diff(
      dayjs.unix(start_date) > dayjs() ? dayjs.unix(start_date) : dayjs(),
      "day"
    );
  return (
    <>
      <CardPrimary className={"h-100 border-0 bg-light"}>
        <div className="row">
          <div className="col-md-12 mb-sm-4">
            <div className="d-flex align-items-start justify-content-between">
              <p className="badge fs-11 text-uppercase  fw-500">
                {getStatus(status)}
              </p>
            </div>

            <div className="row mb-4 text-dark">
              <div className={"col-md-6"}>
                <p className="fs-12 mb-1">Program</p>
                <p className="fw-500 mb-1">{item?.basket_details?.title}</p>
              </div>
              <div className={"col-md-6"}>
                <p className="fs-12 mb-1">
                  Booking ID:{" "}
                  <span className={"fw-500"}>{display_booking_id}</span>
                </p>
                <p className="fs-12">
                  Date:{" "}
                  <span className={"fw-500"}>
                    {dayjs.unix(updated).format("DD MMM, YYYY")}
                  </span>
                </p>
              </div>
            </div>

            <div className="row text-dark my-4">
              {start_time && end_time && (
                <div className="col-md-3">
                  <Label text={"Time Slot"} />
                  <p className="fs-14 fw-500 mb-0">
                    {dayjs.unix(start_time).format("HH: MM a")} -
                    {dayjs.unix(end_time).format("HH: MM a")}
                  </p>
                </div>
              )}

              {instructor_name && (
                <div className="col-md-3 col-6 mb-4">
                  <Label text={"Instructor"} />
                  <p className="fs-14 fw-500 mb-0">{instructor_name}</p>
                </div>
              )}
              <div className="col-md-3 col-6 mb-4">
                <Label text={"Subscription Valid Till"} />
                <div className="fs-14 fw-500 mb-0">
                  {dayjs.unix(end_date).format("DD MMM, YYYY")}
                </div>
              </div>

              {pickup && (
                <div className="col-md-3 col-6 mb-4">
                  <Label text={"Type/On-boarding"} />
                  <div className="fs-14 fw-500 mb-0">{parse(pickup)}</div>
                </div>
              )}

              <div className="col-md-3 col-6 mb-4">
                <Label text={"Price"} />
                <p className="fs-16 fw-500 mb-0">
                  {Utils.convertToIndianCurrency(Math.round(amount))}
                </p>
              </div>

              {status === BookingStatus.SUCCESS && (
                <div className="col-12">
                  <hr />
                  {joining_details && (
                    <>
                      <p className={"fw-500"}>What's Next?</p>
                      {parse(joining_details)}
                    </>
                  )}
                  {things_to_carry && (
                    <>
                      <div className="my-4" />

                      <a
                        className={"text-dark fw-500"}
                        data-toggle="collapse"
                        href={`#${display_booking_id}`}
                        role="button"
                        aria-expanded="false"
                        aria-controls={`${display_booking_id}`}
                      >
                        Things to keep in mind <ChevronDown />
                      </a>
                      <div className="collapse" id={`${display_booking_id}`}>
                        {parse(things_to_carry)}
                      </div>
                    </>
                  )}
                </div>
              )}
              {status === BookingStatus.SUCCESS && (
                <div className="d-flex justify-content-start">
                  <Link href={`/booking/basket/${booking_id}`}>
                    <a
                      onClick={(e) => {
                        Analytics.track("View Booking", { booking_id });
                        e.stopPropagation();
                      }}
                      className={"btn callToActionNormal"}
                    >
                      Join Ongoing Sessions
                    </a>
                  </Link>
                </div>
              )}
            </div>

            <div className="d-flex mb-2">
              {url && (
                <div className="pe-2">
                  <Link href={`/join/${booking_id}/${url}`}>
                    <a
                      onClick={(e) => {
                        e.stopPropagation();
                        Analytics.track("Join Session", { booking_id });
                      }}
                      className={"btn callToActionNormal"}
                      target={"_blank"}
                    >
                      Join Session
                    </a>
                  </Link>
                </div>
              )}

              {can_book_again && (
                <div className="pe-2">
                  <Link href={`/checkout/${id}/`}>
                    <a
                      onClick={(e) => {
                        Analytics.track("Book Again CTA", { booking_id });
                        e.stopPropagation();
                      }}
                      className={"btn callToActionOutlineNormal"}
                    >
                      Book Again
                    </a>
                  </Link>
                </div>
              )}
            </div>

            {message && (
              <CardPrimary className="bg-warning text-dark" bodyClassName="p-2">
                <p className={"mb-0 fw-500 fs-12"}>Note: {message}</p>
              </CardPrimary>
            )}

            <p className="fs-12 mt-3 mb-0 text-right text-muted">
              To raise cancellation request, please mail us at{" "}
              <a
                onClick={() =>
                  Analytics.track("cancellation_request_raised", {
                    package_id: id,
                    title: name,
                  })
                }
                href="mailto:hello@bwtexperiences.com"
              >
                hello@bwtexperiences.com{" "}
              </a>
            </p>
            {/* Go to details */}
          </div>
        </div>
      </CardPrimary>
    </>
  );
};

export default MembershipCard;
