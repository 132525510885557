import { Radio, RadioChangeEvent } from "antd";

import { BookingStatus } from "./types";

type MyBookingsTabsProps = {
  handleChange: (e: RadioChangeEvent) => void;
  bookingStatus: { key: BookingStatus; value: string }[];
  status: BookingStatus;
};

const MyBookingsTabs = ({
  handleChange,
  bookingStatus,
  status,
}: MyBookingsTabsProps) => {
  return (
    <Radio.Group
      value={status}
      size="middle"
      className="mb-4"
      onChange={handleChange}
    >
      {bookingStatus.map((bStatus) => (
        <Radio.Button defaultChecked value={bStatus.key}>
          {bStatus.value}
        </Radio.Button>
      ))}
      )
    </Radio.Group>
  );
};
export default MyBookingsTabs;
