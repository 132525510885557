import { ComponentType } from "react";
import Footer from "common/headerFooters/Footer";
import Header from "common/headerFooters/Header";

const withHeaderFooter = <P extends object>(
  WrapperComponent: ComponentType<P>,
  doNotShowFooter?: boolean
): React.FC<P> => (props) => {
  return (
    <>
      <Header />
      <WrapperComponent {...(props as P)} />
      {!doNotShowFooter && <Footer />}
    </>
  );
};

export default withHeaderFooter;
