import { ChevronDown, HamBurgerMenu, Logout, Search } from "common/Icons/Icons";
import { memo, useState } from "react";

import Analytics from "utils/Analytics";
import AnnouncementBanner from "./announcementBanner";
import Constants from "constants/Constants";
import Dropdown from "common/dropdown/Dropdown";
import HeaderDrawer from "./HeaderDrawer";
import Link from "next/link";
import { PROFILE_TABS } from "components/profile/ProfileUtils";
import PriceDropdown from "common/priceDropdown";
import ProfileImage from "common/profile/ProfileImage";
import ProfileLetter from "common/profile/ProfileLetter";
import styles from "./styles.module.scss";
import { useAuth } from "services/AuthService/AuthProvider";
import { useCommonProvider } from "services/contextProviders/CommonProvider";
import { useLogout } from "utils/customHooks";
import { useRouter } from "next/router";

const Header: React.FC = () => {
  const router = useRouter();

  const { config } = useCommonProvider();

  const { isAuthenticated, userDataLoading, userData } = useAuth();

  const logout = useLogout();

  const [showDrawer, setShowDrawer] = useState(false);

  const isLoggedOut = !isAuthenticated && !userDataLoading;

  const dropdownMenu = (title, menu) => {
    return (
      <>
        {menu.map(({ name, path }) => (
          <Link key={`${name}_${path}`} href={path}>
            <a
              className={`fw-500 ${styles.navDropdownLink}`}
              onClick={() =>
                Analytics.track("dropdown_landing_item", { title: title })
              }
            >
              {name}
            </a>
          </Link>
        ))}
      </>
    );
  };

  const profileDropdownMenu = () => {
    return (
      <>
        {PROFILE_TABS.map(({ title, path, icon }) => (
          <Link key={`profile_menu_${path}`} href={`/profile/${path}`}>
            <a
              className={`${styles.navDropdownLink}`}
              onClick={() =>
                Analytics.track("dropdown_landing_item", { title: title })
              }
            >
              {icon} {title}
            </a>
          </Link>
        ))}
        <div
          className={`pointer ${styles.navDropdownLink}`}
          onClick={() => logout()}
        >
          <Logout /> Logout
        </div>
      </>
    );
  };

  const profileDropDown = () => (
    <li
      className="nav-item"
      onClick={() => Analytics.track("dropdown_profile")}
    >
      <Dropdown overlay={profileDropdownMenu()} leftAlign>
        <div className="d-flex align-items-center pointer ms-4">
          {userData?.profile_pic ? (
            <ProfileImage src={userData.profile_pic} alt="ProfileRoot" />
          ) : (
            <ProfileLetter name={userData?.name} />
          )}
          <ChevronDown />
        </div>
      </Dropdown>
    </li>
  );

  return (
    <>
      <AnnouncementBanner />
      <HeaderDrawer show={showDrawer} onClose={() => setShowDrawer(false)} />
      <nav
        className={`navbar navbar-expand-lg bg-white shadow-sm ${styles.mainNavbar}`}
      >
        <div className="container-fluid">
          <Link href="/">
            <a
              className={`navbar-brand hover-none ${styles.logo}`}
              onClick={() => Analytics.track("nav_logo")}
            >
              <h1 className={"fs-18 fw-600 mb-0 text-dark"}>
                <img
                  width={40}
                  alt="BWT Experiences Logo"
                  src={Constants.LOGO_URL}
                />
                &nbsp;&nbsp;experiences
              </h1>
            </a>
          </Link>

          <div className="d-flex align-items-center">
            <div>
              <span
                onClick={() => {
                  Analytics.track("nav_menu", { loggedIn: false });
                  setShowDrawer(true);
                }}
                className={"navbar-toggler"}
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <HamBurgerMenu height={30} width={30} />
              </span>
            </div>
          </div>

          <div className="collapse navbar-collapse">
            <ul className="navbar-nav ms-auto align-items-md-center p-md-2">
              <li className="nav-item">
                <Link href="/search">
                  <a className="d-md-block d-none rounded-circle shadow-sm p-1">
                    <Search />
                  </a>
                </Link>
              </li>
              <div className="ms-3" />

              <li className="nav-item">
                <Link href={"/one-membership"}>
                  <a
                    className={`nav-link ms-3 ${styles.navLink} ${styles.gradientText}`}
                    onClick={() =>
                      Analytics.track("nav_item", { item: "Weekend Getaways" })
                    }
                  >
                    One Membership
                  </a>
                </Link>
              </li>

              {config
                .filter((it) => it.name == "Online Experiences")
                .map((it) => (
                  <div key={it?.name} className="nav-item">
                    {it?.sub_categories?.length > 0 ? (
                      <Dropdown
                        overlay={dropdownMenu(it?.name, it?.sub_categories)}
                      >
                        <span
                          className={`ms-2 nav-link ${styles.navLink} text-primary d-flex flex-row align-items-center pointer`}
                        >
                          {it?.name}{" "}
                          <ChevronDown height={20} fill={"#1ab277"} />
                        </span>
                      </Dropdown>
                    ) : (
                      <Link href={it?.path}>
                        <a
                          className={`nav-link ms-3 ${styles.navLink}`}
                          onClick={() =>
                            Analytics.track("nav_item", { item: it?.name })
                          }
                        >
                          {it?.name}
                        </a>
                      </Link>
                    )}
                  </div>
                ))}

              {isLoggedOut && (
                <li className="nav-item">
                  <Link
                    href={{
                      pathname: "/login",
                      query: router.query,
                    }}
                  >
                    <a
                      className={"btn callToActionOutlineNormal signUp ms-3"}
                      onClick={() =>
                        Analytics.track("nav_item", { item: "login-signup" })
                      }
                    >
                      <span className={styles.navLink}>Login / Sign Up</span>
                    </a>
                  </Link>
                </li>
              )}

              {!isLoggedOut && profileDropDown()}
              <PriceDropdown />
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default memo(Header);
