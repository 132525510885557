import CardPrimary from "common/Cards/CardPrimary";
import { FC } from "react";
import Label from "common/text/Label";
import { NormalLoader } from "common/loaders/Loaders";
import ProfileImage from "common/profile/ProfileImage";
import ProfileLetter from "common/profile/ProfileLetter";
import ReferralCard from "./referralCard/ReferralCard";
import { useAuth } from "services/AuthService/AuthProvider";

const MyProfile: FC = () => {
  const { userData, userDataLoading } = useAuth();
  if (userDataLoading) return <NormalLoader />;

  const items = [
    { label: "Name", value: userData?.name },
    { label: "Email", value: userData?.email },
    { label: "Mobile", value: `${userData?.country_code} ${userData?.mobile}` },
  ];

  return (
    <>
      <div className="row justify-content-center gx-5 gy-3 pb-5">
        <div className="col-md-3 col-12">
          <CardPrimary className="shadow-sm py-2 mb-3">
            <div className="d-flex justify-content-center mb-3">
              {userData?.profile_pic ? (
                <ProfileImage
                  size={100}
                  src={userData.profile_pic}
                  alt="ProfileRoot"
                />
              ) : (
                <ProfileLetter size={100} big name={userData?.name} />
              )}
            </div>
            <p className="mb-2 text-center fs-18 fw-500">{userData?.name}</p>
          </CardPrimary>
          <ReferralCard userData={userData} />
        </div>
        <div className="col-md-7 col-12">
          <CardPrimary className="shadow-sm">
            <div className="row">
              {items.map(({ label, value }) => (
                <div key={label} className="col-md-6 col-12 mb-4">
                  <div className="mx-2 border-bottom">
                    <Label className="mb-1 fs-14 fw-500" text={label} />
                    <p className={"mb-2 fs-16 fw-500"}>{value ?? "--"}</p>
                  </div>
                </div>
              ))}
            </div>
          </CardPrimary>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
