import { FC, useEffect } from "react";

import Analytics from "utils/Analytics";
import AnimationFiles from "common/LottieFiles/AnimationFiles";
import { CardLoader } from "common/loaders/Loaders";
import CardPrimary from "common/Cards/CardPrimary";
import ExperienceCard from "common/Cards/ExperienceCard/ExperienceCard";
import Link from "next/link"
import LottieImport from "common/LottieFiles/Lottie";
import Utils from "utils/Utils";
import { useGet } from "services/api/useFetch";

const MyWishList: FC = () => {
    const [wishList, { loading }, getWishList] = useGet("v1/users/me/likes/packages/", null, { initialLoading: true });

    useEffect(() => {
        getWishList();
    }, [])

    if (loading)
        return (
            <div className="container mt-5">
                <div className={'row g-0'}>
                    {[1, 2, 3, 4].map(
                        (it) => <div key={it} className="col-md-3 col-lg-3 col-6">
                            <CardLoader />
                        </div>)}
                </div>
            </div>
        );

    if (!Utils.arrayCheck(wishList).length)
        return <CardPrimary>
            <LottieImport speed={1} width={300} height={'auto'} file={AnimationFiles.EmptyWishList} />

            <p className="fs-18 fw-500 text-center">
                Nothing found here. Add trips to your wish list.
            </p>
            <div className="d-flex flex-row justify-content-center">
                <Link href={'/'} >
                    <a className={'btn callToAction mt-4'} onClick={() => Analytics.track('start_exploring_cta_user_wish_list')}>
                        Start Exploring
                    </a>
                </Link>
            </div>

        </CardPrimary>

    return (
        <div>
            <div className="row">
                {
                    wishList.map((it) => <div key={it.id} className="col-md-3 col-6 mb-4">
                        <ExperienceCard package={it.package} />
                    </div>)
                }
            </div>
        </div>
    );
}

export default MyWishList