import { FC, memo } from "react";
import styles from "./Profile.module.scss";

interface ProfileLetterProps {
    name: string,
    big?: boolean,
    size?: number,
    fontSize?: number
}

const ProfileLetter: FC<ProfileLetterProps> = ({ name, big, size, fontSize }) => {
    size = size || (big ? 60 : 30);
    fontSize = fontSize || (big ? 36 : 18);
    return (
        <div className={`${styles.profileLetter} shadow-sm`} style={{ width: size, height: size, fontSize }}>
            {name?.charAt(0) || '-'}
        </div>
    )
}

export default memo(ProfileLetter);