import { CSSProperties } from "react";
import { ChevronUp } from "common/Icons/Icons";

type ImportantBookingUpdatesProps = {
  onClick: () => void;
  style?: CSSProperties;
};

const FabulousAnnouncements = ({
  onClick,
  style = {
    bottom: "48px",
  },
}: ImportantBookingUpdatesProps) => {
  return (
    <div
      onClick={onClick}
      style={style}
      className="fixed-bottom m-4 text-center"
    >
      <div className="bg-gradient py-2 px-4 br-4 text-white cursor-pointer">
        <div className="d-flex align-items-center justify-content-between">
          <div>Fabulous Announcements</div>
          <div className="fw-500">
            Open <ChevronUp fill="#FFF" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FabulousAnnouncements;
