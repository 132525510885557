import Button from "common/buttons/Button";
import router from "next/router";
const AnnouncementBanner = ({ borderRadius = 0 }) => {
  return (
    <div className={`bg-gradient py-2 br-${borderRadius}`}>
      <div className="d-flex align-items-center justify-content-between px-2">
        <div className="d-flex align-items-center">
          <div>
            <p className="lead text-white fw-600 mb-1 fs-18 br-4">
              Daily Live Yoga Sessions now only at 499/-
            </p>
          </div>
        </div>
        <div className="flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
          <Button
            onClick={() => router.push("/discover/online-experiences/yoga")}
            className="callToAction"
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AnnouncementBanner;
