import { EmptyClose } from "common/Icons/Icons";
import ImportantBookingUpdates from "./ImportantUpdates";

const AnnouncementsDrawer = ({ show, onHide }) => {
  if (!process.browser) return null;

  const isDesktop = window.innerWidth > 768;

  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 1099,
        background: "#FFF",
        padding: "24px",
        overflow: "auto",
      }}
      className={show ? "d-block" : "d-none"}
    >
      <div className="d-flex justify-content-between mb-4">
        <div>
          <h5>Important Updates</h5>
        </div>
        <div onClick={onHide} className="cursor-pointer">
          <EmptyClose />
        </div>
      </div>
      <ImportantBookingUpdates />
    </div>
  );
};
export default AnnouncementsDrawer;
