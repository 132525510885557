import { FC, memo, ReactNode, useState } from "react";
import { Collapse } from "react-bootstrap";

interface CustomCollapseProps {
  header: string | ReactNode;
  defaultShow?: boolean;
  children?: ReactNode;
  className?: string;
}

const CustomCollapse: FC<CustomCollapseProps> = ({
  header,
  children,
  defaultShow = false,
  className,
}) => {
  const [show, setShow] = useState(defaultShow);
  return (
    <div className={className}>
      <div className="pointer" onClick={() => setShow((_show) => !_show)}>
        {header}
      </div>
      <Collapse in={show}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default memo(CustomCollapse);
